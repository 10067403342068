import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-2xl">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-gray-700 mb-4">
                Welcome to Chat NSFW. Your privacy is critically important to us.
            </p>
            <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>
            <p className="text-gray-700 mb-4">
                We collect information directly from you when you register on our site, log in, or use our services.
                This may include your email address, password, and any prompts or messages you send or receive using our platform.
            </p>
            <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
            <p className="text-gray-700 mb-4">
                The information we collect is used to provide and improve our services. Specifically, we use your data to:
            </p>
            <ul className="list-disc list-inside text-gray-700 mb-4">
                <li>Manage your account and provide customer support</li>
                <li>Improve our services and personalize your experience</li>
                <li>Send you updates, security alerts, and other information</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">Data Security</h2>
            <p className="text-gray-700 mb-4">
                We implement a variety of security measures to maintain the safety of your personal information.
                Your data is encrypted and stored securely, and we use secure methods to transmit your information.
                Only authorized personnel have access to this information, and they are required to keep it confidential.
            </p>
            <h2 className="text-2xl font-bold mb-4">Your Control Over Your Data</h2>
            <p className="text-gray-700 mb-4">
                You have the right to access, modify, or delete your personal information at any time. You can do this by logging into your account and adjusting your settings.
                If you need assistance, please contact our support team.
            </p>
            <h2 className="text-2xl font-bold mb-4">Third-Party Services</h2>
            <p className="text-gray-700 mb-4">
                We do not share your personal information with third parties, except as necessary to provide our services or comply with the law.
                Any third-party services we use are carefully vetted to ensure they adhere to strict privacy standards.
            </p>
            <h2 className="text-2xl font-bold mb-4">Changes to This Policy</h2>
            <p className="text-gray-700 mb-4">
                We may update this privacy policy from time to time to reflect changes in our practices or the law. When we do, we will notify you by updating the date at the top of this policy.
                We encourage you to review this policy periodically to stay informed about how we protect your information.
            </p>
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <p className="text-gray-700 mb-4">
                If you have any questions or concerns about this privacy policy, please contact us at privacy@chatnsfw.com.
            </p>
            <div className="flex justify-between">
                {/* <Link to="/login" className="text-blue-500">Go back to Login</Link> */}
                <Link to="/register" className="text-blue-500">Go back to Register</Link>
            </div>
        </div>
    </div>
);

export default PrivacyPolicy;
