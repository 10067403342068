import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded shadow-md w-full max-w-2xl">
            <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
            <p className="text-gray-700 mb-4">
                Welcome to Chat NSFW. These terms outline the rules and guidelines for using our platform. By accessing our site, you accept these terms. If you disagree with any part of the terms, please do not use our services.
            </p>
            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="text-gray-700 mb-4">
                Chat NSFW is an AI-powered platform for conversations on various topics, including sensitive and explicit content. You are responsible for your interactions and the content you share on our platform.
            </p>
            <h2 className="text-2xl font-bold mb-4">2. User Responsibilities</h2>
            <p className="text-gray-700 mb-4">
                You must not use our services for illegal activities. Misuse of the platform, including sharing illegal content, can result in the suspension or termination of your account. Chat NSFW is not responsible for your actions or experiments conducted on the platform.
            </p>
            <h2 className="text-2xl font-bold mb-4">3. Content</h2>
            <p className="text-gray-700 mb-4">
                While we strive to provide accurate information, the AI-generated content may sometimes be incorrect or misleading. We do not endorse or guarantee the accuracy of the information provided. Use your best judgment and exercise caution when interpreting and acting on the content.
            </p>
            <h2 className="text-2xl font-bold mb-4">4. Data Security and Privacy</h2>
            <p className="text-gray-700 mb-4">
                We prioritize your privacy and data security. All data transmitted through our services is encrypted and stored securely. Only authorized personnel have access to your data, and they are required to keep it confidential.
            </p>
            <h2 className="text-2xl font-bold mb-4">5. Limitation of Liability</h2>
            <p className="text-gray-700 mb-4">
                We are not liable for any direct, indirect, incidental, or consequential damages resulting from your use of the services. This includes but is not limited to, lost profits, lost data, or other damages, even if we have been advised of the possibility of such damages.
            </p>
            <h2 className="text-2xl font-bold mb-4">6. Indemnification</h2>
            <p className="text-gray-700 mb-4">
                You agree to indemnify and hold us harmless from any claims, losses, or damages, including legal fees, arising from your use of the services, violation of these terms, or infringement of any third party's rights.
            </p>
            <h2 className="text-2xl font-bold mb-4">7. Disclaimer</h2>
            <p className="text-gray-700 mb-4">
                The services are provided "as is" and "as available" without any warranties, express or implied. We disclaim all warranties, including but not limited to, the implied warranties of merchantability and fitness for a particular purpose.
            </p>
            <h2 className="text-2xl font-bold mb-4">8. User Data</h2>
            <p className="text-gray-700 mb-4">
                We maintain data transmitted through our services for performance management. While we perform regular backups, you are responsible for all data you transmit or that relates to your activities. We are not liable for any loss or corruption of such data.
            </p>
            <h2 className="text-2xl font-bold mb-4">9. Changes to Terms</h2>
            <p className="text-gray-700 mb-4">
                We may update these terms periodically to reflect changes in our practices or the law. When we do, we will update the date at the top of this page. We encourage you to review these terms regularly.
            </p>
            <h2 className="text-2xl font-bold mb-4">10. Contact Us</h2>
            <p className="text-gray-700 mb-4">
                If you have any questions or concerns about these terms, please contact us at support@chatnsfw.com.
            </p>
            <div className="flex justify-between">
                <Link to="/register" className="text-blue-500">Go back to Register</Link>
            </div>
        </div>
    </div>
);

export default TermsOfService;
